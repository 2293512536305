import React, { createContext, ReactElement, useContext } from 'react'
import { useTheme } from 'styled-components'
import { useRouter } from 'next/router'
import useMediaQuery from '@/hooks/useMediaQuery'
import { SizeTypes } from '@/types/size'
import { BreakPointDisplaySizes, BreakPointDisplaySizesMediathek } from '@/helpers/pageHelper'
import { getBreakpointsStart, getBreakpointsSizes, BreakPointProps } from '@/helpers/breakpoint'

type MediaQueryContextProps = {
    currentDevice?: SizeTypes
}

export const MediaQueryContext = createContext<MediaQueryContextProps>({})

type MediaQueryProviderProps = {
    children?: ReactElement | ReactElement[]
}

const MediaQueryProvider = ({ children }: MediaQueryProviderProps): ReactElement => {
    const theme = useTheme()
    const { pathname } = useRouter()
    const [currentDevice, setCurrentDevice] = React.useState<SizeTypes>()
    const breakpointsMap = getBreakpointsSizes(
        pathname.includes('tv-video') ? BreakPointDisplaySizesMediathek : BreakPointDisplaySizes,
        theme as BreakPointProps
    )
    const tabletBreakpoint = getBreakpointsStart(SizeTypes.TABLET, breakpointsMap)
    const desktopStart = getBreakpointsStart(SizeTypes.DESKTOP, breakpointsMap)

    const isMobile = useMediaQuery(`only screen and (max-width: ${tabletBreakpoint - 1}px)`)
    const isTablet = useMediaQuery(
        `only screen and (min-width: ${tabletBreakpoint}px) and (max-width: ${desktopStart - 1}px)`
    )
    const isDesktop = useMediaQuery(`only screen and (min-width: ${desktopStart}px)`)

    React.useEffect(() => {
        if (isMobile) {
            setCurrentDevice(SizeTypes.MOBILE)
        } else if (isTablet) {
            setCurrentDevice(SizeTypes.TABLET)
        } else if (isDesktop) {
            setCurrentDevice(SizeTypes.DESKTOP)
        }
    }, [isMobile, isTablet, isDesktop])

    return (
        <MediaQueryContext.Provider
            value={{
                currentDevice,
            }}
        >
            <>{children}</>
        </MediaQueryContext.Provider>
    )
}

export const useMediaQueryContext = () => useContext(MediaQueryContext)

export default MediaQueryProvider
